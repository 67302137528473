import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { localePrefix } from "../../../pages";
import { OnboardingStep } from "../../onboarding/types";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { Link } from "gatsby";
import { t, Trans } from "@lingui/macro";

export function HowItWorksSection() {
  const { locale } = useContext(pageContext);

  const tt = useTranslations();

  return (
    <section className="py-[4.5rem]">
      <div className="max-w-7xl mx-auto px-4">
        {/* Headings */}
        <h2 className="m-0 uppercase text-center tracking-widest text-base font-medium">{tt.subheader}</h2>
        <h3 className="m-0 text-center mt-4 text-[1.6875rem] leading-[2.25rem] md:text-4xl font-normal">
          {tt.header}
        </h3>

        {/* Steps */}
        <div className="flex flex-col items-center md:items-start md:flex-row md:justify-center space-y-12 md:space-y-0 md:space-x-12 mt-12 mx-auto">
          {/* Step one */}
          <div className="flex flex-col items-center text-center w-80">
            <StaticImage
              src="../../../../static/images/logged-out-home/step-1.jpg"
              width={224}
              objectFit="contain"
              alt="quiz"
              className="max-w-[180px] md:max-w-[224px] rounded-full bg-lighter-grey overflowfix"
            />
            <h4 className="mt-3 text-2xl font-medium whitespace-pre-line">{tt.stepOneTitle}</h4>
            <p className="m-0 mt-2 font-normal text-base md:text-lg max-w-[14rem] mx-auto whitespace-pre-line">
              {tt.stepOneDescription}
            </p>
          </div>

          {/* Step two */}
          <div className="flex flex-col items-center text-center w-80">
            <StaticImage
              src="../../../../static/images/logged-out-home/step-2.jpg"
              width={224}
              objectFit="contain"
              alt="personalized"
              className="max-w-[180px] md:max-w-[224px] rounded-full bg-lighter-grey overflowfix"
            />
            <h4 className="mt-3 text-2xl font-medium whitespace-pre-line">{tt.stepTwoTitle}</h4>
            <p className="m-0 mt-2 font-normal text-base md:text-lg max-w-[16.25rem] sm:max-w-[18.5rem] mx-auto whitespace-pre-line">
              {tt.stepTwoDescription}
            </p>
          </div>

          {/* Step three */}
          <div className="flex flex-col items-center text-center w-80">
            <StaticImage
              src="../../../../static/images/logged-out-home/step-3.jpg"
              width={224}
              objectFit="contain"
              alt="guidance"
              className="max-w-[180px] md:max-w-[224px] rounded-full bg-lighter-grey overflowfix"
            />
            <h4 className="mt-3 text-2xl font-medium whitespace-pre-line">{tt.stepThreeTitle}</h4>
            <p className="m-0 mt-2 font-normal text-base md:text-lg max-w-[14rem] mx-auto whitespace-pre-line">
              {tt.stepThreeDescription}
            </p>
          </div>
        </div>

        {/* CTA */}
        <Link
          to={`${localePrefix[locale]}/onboarding/${OnboardingStep.GENDER}`}
          className="!mt-[3.375rem] md:!mt-[4.5rem] block mx-auto max-w-max font-medium text-xl bg-green text-white px-8 py-3 rounded-full hover:underline focus:opacity-95 transition-opacity duration-75"
        >
          {tt.cta}
        </Link>
      </div>
    </section>
  );
}

function useTranslations() {
  return {
    subheader: t({
      id: "HowItWorksSection.subheader",
      message: "How it works",
    }),
    header: (
      <Trans id="HowItWorksSection.header">
        You tell us your goals.<br></br>
        <b className="whitespace-nowrap">We’ll help you get there.</b>
      </Trans>
    ),
    stepOneTitle: t({
      id: "HowItWorksSection.stepOneTitle",
      message: "Take the quiz",
    }),
    stepOneDescription: t({
      id: "HowItWorksSection.stepOneDescription",
      message: "The free quiz only\ntakes a few minutes.",
    }),
    stepTwoTitle: t({
      id: "HowItWorksSection.stepTwoTitle",
      message: "Get your personalized plan",
    }),
    stepTwoDescription: t({
      id: "HowItWorksSection.stepTwoDescription",
      message: "We’ll create a meal plan that matches your preferences and needs.",
    }),
    stepThreeTitle: t({
      id: "HowItWorksSection.stepThreeTitle",
      message: "Take it one step at a time",
    }),
    stepThreeDescription: t({
      id: "HowItWorksSection.stepThreeDescription",
      message: "Our expert guidance keeps you on the right track.",
    }),
    cta: t({
      id: "HowItWorksSection.cta",
      message: "Try it free for 7 days",
    }),
  };
}
